import axios from "axios";
import Qs from "qs";

const newAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.API_URL
  });

  instance.interceptors.request.use(
    config => {
      if (!config.data) config.data = {};
      if (config.headers["Content-Type"] === "multipart/form-data") {
        config.data.append("token", localStorage.token);
      } else {
        config.data.token = localStorage.token;
        config.data = Qs.stringify(config.data, {
          encoder: (str, defaultEncoder, charset, type) => {
            if (type === "value") {
              if (typeof str == "boolean") {
                return str ? 1 : 0;
              }
              return str;
            } else {
              return str;
            }
          },
          arrayFormat: "brackets"
        });
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  return instance;
};

export default newAxiosInstance;
