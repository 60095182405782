import newInstance from "./axios-plain";
import { Toast } from "vant";
import { VanToast } from "vant/types/toast";

const toasts: VanToast[] = [];
const instance = newInstance();

instance.interceptors.request.use(
  config => {
    toasts.push(Toast.loading("请求中..."));
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    toasts.pop()?.clear();
    if (response.status == 200 && (!response.data.status || Number(response.data.status) < 1 || Number(response.data.status) >= 1000)) {
      Toast.fail(response.data.msg);
      return Promise.reject({
        response: response,
        request: response.request,
        message: "server returns an error",
        config: response.config
      });
    }
    return response;
  },
  error => {
    toasts.pop()?.clear();
    Toast.fail("抱歉, 服务器开小差了");
    return Promise.reject(error);
  }
);

export default instance;

// {
//     // `data` 由服务器提供的响应
//     data: { },

//     // `status` 来自服务器响应的 HTTP 状态码
//     status: 200,

//         // `statusText` 来自服务器响应的 HTTP 状态信息
//         statusText: 'OK',

//             // `headers` 服务器响应的头
//             headers: { },

//     // `config` 是为请求提供的配置信息
//     config: { },
//     // 'request'
//     // `request` is the request that generated this response
//     // It is the last ClientRequest instance in node.js (in redirects)
//     // and an XMLHttpRequest instance the browser
//     request: { }
// }
