import Component from "vue-class-component";
Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

import "@/assets/iconfont/iconfont.css";
import "@/assets/css/base.css";

import { Toast } from "vant";
Toast.setDefaultOptions("loading", { forbidClick: true, duration: 0 });
Toast.allowMultiple(true);

import axios from "./helpers/axios";
Vue.prototype.$axios = axios;

localStorage.landUrl = window.location.href.split("#")[0];

import { toUrl, toMiniApp, toMiniAppWeb, isEmptyObject, readyWx, scanCode, secToTime, loadDisplay, checkPermissions } from "./helpers/lib";
Vue.prototype.$toUrl = toUrl;
Vue.prototype.$toMiniApp = toMiniApp;
Vue.prototype.$toMiniAppWeb = toMiniAppWeb;
Vue.prototype.$isEmptyObject = isEmptyObject;
Vue.prototype.$readyWx = readyWx;
Vue.prototype.$scanCode = scanCode;
Vue.prototype.$secToTime = secToTime;
Vue.prototype.$loadDisplay = loadDisplay;
Vue.prototype.$checkPermissions = checkPermissions;

// import { ElTree, ElTable, ElTableColumn} from "./helpers/element-ui"
// Vue.component("el-tree", ElTree);
// Vue.component("el-table", ElTable);
// Vue.component("el-table-column", ElTableColumn);

import { Lazyload } from "vant";

Vue.use(Lazyload, {
  lazyComponent: true
});

import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

new Vue({
  router,
  render: h => h(App),
  mounted() {
    this.$loadDisplay();
  }
}).$mount("#app");
