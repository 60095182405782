import wx from "weixin-js-sdk";
import { Toast } from "vant";
import { AxiosInstance } from "axios";

const imageKeyToUrl = function(key: string | undefined | null) {
  if (!key) return "";
  if (key.startsWith("http")) return key;
  return "https://resource.letfungo.com/" + key;
};

const toMiniAppWeb = function(url: string) {
  if (!url.startsWith("http")) {
    url = process.env.VUE_APP_DOMAIN + url;
  }
  const miniProgram = navigator.userAgent.indexOf("miniProgram") > -1;
  if (miniProgram) {
    wx.miniProgram.navigateTo({ url: "../../pages/web/web?url=" + encodeURIComponent(url) });
  } else {
    window.open(url, "_self");
  }
};

const toMiniApp = function(url: string) {
  const miniProgram = navigator.userAgent.indexOf("miniProgram") > -1;
  if (!miniProgram) {
    Toast.fail("请在小程序内操作");
    return;
  }
  wx.miniProgram.navigateTo({ url: url });
};

const toUrl = function(url: string, isReplace = false) {
  if (!url.startsWith("/web/manager/v")) {
    toMiniAppWeb(url);
    return;
  }
  if (!url.startsWith("http")) {
    url = process.env.VUE_APP_DOMAIN + url;
  }
  if (isReplace) {
    location.replace(url);
  } else {
    window.open(url, "_self");
  }
  localStorage.landUrl = window.location.href.split("#")[0];
};

const loadDisplay = function() {
  const ele = document.getElementById("app");
  if (ele) {
    if (localStorage.myCacheShowCharge == "1") {
      ele.classList.replace("hide-charge", "show-charge");
    } else {
      ele.classList.replace("show-charge", "hide-charge");
    }
    if (localStorage.myCacheShowReplace == "1") {
      ele.classList.replace("hide-replace", "show-replace");
    } else {
      ele.classList.replace("show-replace", "hide-replace");
    }
    if (localStorage.myCacheShowPlan == "1") {
      ele.classList.replace("hide-plan", "show-plan");
    } else {
      ele.classList.replace("show-plan", "hide-plan");
    }
  }
};

const isEmptyObject = function(obj: Record<string, any>) {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

const checkPermissions = function(axios: AxiosInstance, permissions: number[]): Promise<{ permissionList: number[]; isPlatformManager: boolean }> {
  return axios.post("/api/manage/checkPermissions", { permissions: permissions.join(",") }).then(e => {
    return e.data.data;
  });
};

const getPlatform = function() {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //Android
  if (isAndroid) return "android";
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //iOS
  if (isiOS) return "ios";
};

const readyWx = function(axios: AxiosInstance, jsApiList: string[], debug?: boolean, url2?: string) {
  // const url = getPlatform() == "ios" ? process.env.VUE_APP_DOMAIN + process.env.VUE_APP_PUBLIC_PATH + "/index" : window.location.href.split("#")[0];
  let url;
  if (url2) {
    url = url2;
  } else {
    url = getPlatform() == "ios" ? localStorage.landUrl : window.location.href.split("#")[0];
  }
  wx.error((res: { errMsg: string }) => {
    if (res.errMsg == "config:invalid signature") {
      if (!url2) {
        readyWx(axios, jsApiList, debug, getPlatform() != "ios" ? localStorage.landUrl : window.location.href.split("#")[0]);
      }
    }
  });
  axios.post("/api/manage/weiXinConfig", { url: url }).then(res => {
    const data = res.data;
    localStorage.setItem("appId", data.data.appId);
    localStorage.setItem("timestamp", data.data.timestamp);
    localStorage.setItem("nonceStr", data.data.nonceStr);
    localStorage.setItem("sign", data.data.sign);
    wx.config({
      debug: debug || false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: data.data.appId, // 必填，公众号的唯一标识
      timestamp: data.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
      signature: data.data.sign, // 必填，签名
      jsApiList: jsApiList
    });
  });
};

const scanCode = function(callback: (content: string) => void, needResult = 1) {
  wx.scanQRCode({
    needResult: needResult,
    scanType: ["qrCode", "barCode"],
    success: (res: { resultStr: string }) => {
      callback(res.resultStr.trim());
    },
    error: () => {
      alert("扫码失败");
    }
  });
};

const secToTime = function(s: number) {
  let t = "";
  if (s > 0) {
    const day = Math.floor(s / 3600 / 24);
    const hour = Math.floor(s / 3600) % 24;
    const min = Math.floor(s / 60) % 60;
    const sec = s % 60;
    if (day != 0) {
      t = day + "天";
    }
    if (hour != 0) {
      t += hour + "时";
    }
    if (min != 0) {
      t += min + "分";
    }
    if (sec != 0) {
      t += sec + "秒";
    }
  } else {
    t = "0秒";
  }
  return t;
};

export { toMiniApp, toMiniAppWeb, toUrl, isEmptyObject, readyWx, scanCode, secToTime, loadDisplay, checkPermissions, imageKeyToUrl };
