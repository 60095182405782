






// @ is an alias to /src
import Vue from "vue";
import { Empty } from "vant";

export default Vue.extend({
  components: {
    [Empty.name]: Empty
  }
});
