import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test/index.vue"),
    meta: {
      title: "测试"
    }
  },
  {
    path: "/test2",
    name: "test2",
    component: () => import("../views/test/index2.vue"),
    meta: {
      title: "测试2"
    }
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("../views/test/sample.vue"),
    meta: {
      title: "sample"
    }
  },

  {
    path: "/index",
    name: "index",
    component: () => import("../views/index/index.vue"),
    meta: {
      title: "管理员后台",
      keepAlive: true
    }
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/help.vue"),
    meta: {
      title: "帮助"
    }
  },
  {
    path: "/app/image",
    name: "app-image",
    component: () => import("../views/app/image.vue"),
    props: true,
    meta: {
      title: "详情"
    }
  },
  {
    path: "/help/more",
    name: "help-more",
    component: () => import("../views/help/more-help.vue"),
    meta: {
      title: "更多操作"
    }
  },
  {
    path: "/battery-plan-order/device-change-log/:planOrderId",
    name: "battery-plan-order-device-change-log",
    component: () => import("../views/battery-plan-order/device-change-log.vue"),
    meta: {
      title: "租售订单设备更换记录"
    }
  },
  {
    path: "/battery-plan-order/battery-switch",
    name: "battery-plan-order-battery-switch",
    component: () => import("../views/battery-plan-order/battery-switch.vue"),
    meta: {
      title: "租售订单更换电池"
    }
  },
  {
    path: "/battery-plan-order/replace-manual/:id",
    name: "battery-plan-order-replace-manual",
    component: () => import("../views/battery-plan-order/replace-manual.vue"),
    meta: {
      title: "管理员换电"
    }
  },
  {
    path: "/battery-plan-order/user-device/:id",
    name: "battery-plan-order-user-device",
    component: () => import("../views/battery-plan-order/user-device/user-device.vue"),
    meta: {
      title: "用户设备"
    }
  },
  {
    path: "/battery-plan-order/team-manage/:id",
    name: "battery-plan-order-team-manage",
    component: () => import("../views/battery-plan-order/team-manage/team-manage.vue"),
    meta: {
      title: "团队设备"
    }
  },
  {
    path: "/battery-plan-order/extra-pay/list/:planOrderId",
    name: "battery-plan-order-extra-pay-list",
    component: () => import("../views/battery-plan-order/extra-pay/list.vue"),
    meta: {
      title: "补充支付"
    }
  },
  {
    path: "/battery-plan-order/extra-pay/save/:extraPayId",
    name: "battery-plan-order-extra-pay-save",
    component: () => import("../views/battery-plan-order/extra-pay/save.vue"),
    meta: {
      title: "补充支付保存"
    }
  },

  {
    path: "/battery-order/list",
    name: "battery-order-list",
    component: () => import("../views/battery-order/list.vue"),
    meta: {
      title: "充电订单",
      keepAlive: true
    }
  },
  {
    path: "/battery-order/detail/:id",
    name: "battery-order-detail",
    component: () => import("../views/battery-order/detail/detail.vue"),
    meta: {
      title: "充电订单详情"
    }
  },

  {
    path: "/manager/list",
    name: "manager-list",
    component: () => import("../views/manager/list.vue"),
    meta: {
      title: "管理员列表"
    }
  },
  {
    path: "/device/statistics",
    name: "device-statistics",
    component: () => import("../views/device/statistics.vue"),
    meta: {
      title: "设备列表"
    }
  },
  {
    path: "/device/bind-business",
    name: "device-bind-business",
    component: () => import("../views/device/bind-business.vue"),
    meta: {
      title: "设备绑定运营商"
    }
  },
  {
    path: "/device/ebike-map",
    name: "device-ebike-map",
    component: () => import("../views/device/ebike-map.vue"),
    meta: {
      title: "车辆地图"
    }
  },
  {
    path: "/device/cabinet-map",
    name: "device-cabinet-map",
    component: () => import("../views/device/cabinet-map.vue"),
    meta: {
      title: "柜子地图"
    }
  },
  {
    path: "/device/battery-map",
    name: "device-battery-map",
    component: () => import("../views/device/battery-map.vue"),
    meta: {
      title: "电池地图"
    }
  },
  {
    path: "/device/search",
    name: "device-search",
    component: () => import("../views/device/search.vue"),
    meta: {
      title: "设备搜索"
    }
  },
  {
    path: "/device/cabinet",
    name: "device-cabinet",
    component: () => import("../views/device/cabinet.vue"),
    meta: {
      title: "充换电柜"
    }
  },
  {
    path: "/device/data",
    name: "device-data",
    component: () => import("../views/device/data.vue"),
    meta: {
      title: "硬件数据"
    }
  },
  {
    path: "/device/cabinet-bind/:cabinetId",
    name: "device-cabinet-bind",
    component: () => import("../views/device/cabinet-bind.vue"),
    props: true,
    meta: {
      title: "充换电柜录入"
    }
  },
  {
    path: "/device/cabinet-list",
    name: "device-cabinet-list",
    component: () => import("../views/device/cabinet-list.vue"),
    meta: {
      title: "设备列表"
    }
  },
  {
    path: "/device/repair",
    name: "device-repair",
    component: () => import("../views/device/repair.vue"),
    meta: {
      title: "设备维修"
    }
  },
  {
    path: "/device/install/:cabinetId",
    name: "device-install",
    component: () => import("../views/device/install/install.vue"),
    meta: {
      title: "设备安装"
    }
  },
  {
    path: "/device/install-plain/:cabinetId",
    name: "device-install-plain",
    component: () => import("../views/device/install/install-plain.vue"),
    meta: {
      title: "设备安装"
    }
  },

  /**
   * 站点
   */
  {
    path: "/site/stat",
    name: "site-stat",
    component: () => import("../views/site/stat/stat.vue"),
    props: true,
    meta: {
      title: "站点编辑"
    }
  },
  {
    path: "/site/edit/:siteId/:profile?",
    name: "site-edit",
    component: () => import("../views/site/edit/edit.vue"),
    props: true,
    meta: {
      title: "站点编辑"
    }
  },

  /**
   * 运营商
   */
  {
    path: "/business/apply-list",
    name: "business-apply-list",
    component: () => import("../views/business/apply-list.vue"),
    props: true,
    meta: {
      title: "运营商申请"
    }
  },
  {
    path: "/business/apply",
    name: "business-apply",
    component: () => import("../views/business/apply.vue"),
    props: true,
    meta: {
      title: "运营商申请"
    }
  },
  {
    path: "/business/add",
    name: "business-add",
    component: () => import("../views/business/add.vue"),
    props: true,
    meta: {
      title: "运营商添加"
    }
  },
  {
    path: "/business/apply-wx-partner",
    name: "business-apply-wx-partner",
    component: () => import("../views/business/apply-wx-partner.vue"),
    props: true,
    meta: {
      title: "微信特约商户申请"
    }
  },

  /**
   * 账户
   */
  {
    path: "/account/stat",
    name: "account-stat",
    component: () => import("../views/account/account-stat.vue"),
    props: true,
    meta: {
      title: "收益统计",
      keepAlive: true
    }
  },
  {
    path: "/account/site-stat",
    name: "account-site-stat",
    component: () => import("../views/account/site-stat.vue"),
    props: true,
    meta: {
      title: "站点收益"
    }
  },
  {
    path: "/account/site-trending",
    name: "account-site-trending",
    component: () => import("../views/account/site-trending.vue"),
    props: true,
    meta: {
      title: "站点趋势"
    }
  },
  {
    path: "/account/withdraw-apply",
    name: "account-withdraw-apply",
    component: () => import("../views/account/withdraw-apply.vue"),
    props: true,
    meta: {
      title: "申请提现"
    }
  },

  /**
   * 账单
   */
  {
    path: "/bill/bill-export",
    name: "bill-export",
    component: () => import("../views/bill/bill-export.vue"),
    props: true,
    meta: {
      title: "账单导出"
    }
  },

  /**
   * 服务费
   */
  {
    path: "/service-plan/plan-list/:type",
    name: "service-plan-list",
    component: () => import("../views/service-plan/plan-list.vue"),
    meta: {
      title: "服务列表",
      keepAlive: true
    }
  },
  {
    path: "/service-plan/plan-save/:id?",
    name: "service-plan-save",
    component: () => import("../views/service-plan/plan-save.vue"),
    meta: {
      title: "服务编辑"
    }
  },
  {
    path: "/service-plan/pay-order-list/:id",
    name: "service-plan-pay-order-list",
    component: () => import("../views/service-plan/pay-order-list.vue"),
    meta: {
      title: "服务支付记录"
    }
  },

  /**
   * 设备服务费
   */
  {
    path: "/service-plan/cabinet/plan-list",
    name: "service-plan-cabinet-list",
    component: () => import("../views/service-plan/cabinet/plan-list/plan-list.vue"),
    meta: {
      title: "设备服务状态",
      keepAlive: true
    }
  },
  {
    path: "/service-plan/cabinet/renew-order",
    name: "service-plan-cabinet-renew-order",
    component: () => import("../views/service-plan/cabinet/plan-list/renew-order.vue"),
    meta: {
      title: "续费记录"
    }
  },

  /**
   * 发票
   */
  {
    path: "/invoice/list",
    name: "invoice-list",
    component: () => import("../views/invoice/invoice-list.vue"),
    meta: {
      title: "开票历史"
    }
  },
  {
    path: "/invoice/apply",
    name: "invoice-apply",
    component: () => import("../views/invoice/invoice-apply.vue"),
    meta: {
      title: "申请开票"
    }
  },
  {
    path: "/invoice/info",
    name: "invoice-info",
    component: () => import("../views/invoice/invoice-info.vue"),
    meta: {
      title: "发票抬头"
    }
  },

  /**
   * levy
   */
  {
    path: "/levy/levy",
    name: "levy-user",
    component: () => import("../views/levy/levy.vue"),
    meta: {
      title: "平台服务费"
    }
  },
  {
    path: "/levy/levy-finance",
    name: "levy-finance",
    component: () => import("../views/levy/levy-finance.vue"),
    meta: {
      title: "平台服务费管理"
    }
  },
  {
    path: "/levy/levy-recharge",
    name: "levy-recharge",
    component: () => import("../views/levy/levy-recharge.vue"),
    meta: {
      title: "平台服务费充值"
    }
  },

  /**
   * 卡券
   */
  {
    path: "/coupon/giveaway",
    name: "coupon-giveaway",
    component: () => import("../views/coupon/giveaway.vue"),
    meta: {
      title: "卡券发放"
    }
  },
  {
    path: "/coupon/list-for-user",
    name: "coupon-list-for-user",
    component: () => import("../views/coupon/list-for-user.vue"),
    meta: {
      title: "用户卡券列表"
    }
  },

  /**
   * 充电卡
   */
  {
    path: "/card/ship-method",
    name: "card-ship-method",
    component: () => import("../views/card/ship-method.vue"),
    meta: {
      title: "设置制卡信息"
    }
  },
  {
    path: "/card/ship-order",
    name: "card-ship-order",
    component: () => import("../views/card/ship-order.vue"),
    meta: {
      title: "制卡请求"
    }
  },
  {
    path: "/card/list",
    name: "card-list",
    component: () => import("../views/card/list.vue"),
    meta: {
      title: "卡片列表"
    }
  },

  /**
   * 审批
   */
  {
    path: "/review/apply/account-balance-transfer",
    name: "review-apply-account-balance-transfer",
    component: () => import("../views/review/apply/account-balance-transfer.vue"),
    props: true,
    meta: {
      title: "提交申请"
    }
  },
  {
    path: "/review/apply/account-owner-transfer",
    name: "review-apply-account-owner-transfer",
    component: () => import("../views/review/apply/account-owner-transfer.vue"),
    props: true,
    meta: {
      title: "提交申请"
    }
  },
  {
    path: "/review/apply/account-confirm",
    name: "review-apply-account-confirm",
    component: () => import("../views/review/apply/account-confirm.vue"),
    props: true,
    meta: {
      title: "提交申请"
    }
  },
  {
    path: "/review/audit/detail/:id",
    name: "review-audit-detail",
    component: () => import("../views/review/audit/detail.vue"),
    props: true,
    meta: {
      title: "审批详情"
    }
  },
  {
    path: "/review/audit/list",
    name: "review-audit-list",
    component: () => import("../views/review/audit/list.vue"),
    props: true,
    meta: {
      title: "审批列表",
      keepAlive: true
    }
  },

  /**
   * 迁移
   */
  {
    path: "/migrate-from-dianbao",
    name: "migrate-from-dianbao",
    component: () => import("../views/migrate-from-dianbao/index.vue"),
    meta: {
      title: "电宝运营商迁移"
    }
  },
  {
    path: "/migrate-agency-from-dianbao",
    name: "migrate-agency-from-dianbao",
    component: () => import("../views/migrate-from-dianbao/agency.vue"),
    meta: {
      title: "电宝运营商迁移"
    }
  },
  /**
   * user
   */
  {
    path: "/user/repair",
    name: "user-repair",
    component: () => import("../views/user/repair.vue"),
    meta: {
      title: "设备报修"
    }
  },
  {
    path: "/user/address",
    name: "user-address",
    component: () => import("../views/user/address/address.vue"),
    meta: {
      title: "地址"
    }
  },
  /**
   * internal
   */
  {
    path: "/internal/sim",
    name: "internal-sim",
    component: () => import("../views/internal/sim.vue"),
    meta: {
      title: "sim日志"
    }
  },

  // ---------- examples
  {
    path: "/exp/user",
    name: "user",
    component: () => import("../exp/user/index.vue"),
    meta: {
      title: "会员中心"
    }
  },
  {
    path: "/exp/cart",
    name: "cart",
    component: () => import("../exp/cart/index.vue"),
    meta: {
      title: "购物车"
    }
  },
  {
    path: "/exp/goods",
    name: "goods",
    component: () => import("../exp/goods/index.vue"),
    meta: {
      title: "商品详情"
    }
  },
  { path: "*", component: NotFound, meta: { title: "page not found" } }
];

const base = process.env.VUE_APP_PUBLIC_PATH;
// console.log("prcess_env:" + JSON.stringify(process.env));
// console.log("base:" + base);
// routes.forEach(route => {
//   if (route.path.startsWith('/')) {
//     route.path = base + route.path;
//   }
// });
const router = new VueRouter({
  mode: "history",
  base: base,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export default router;
